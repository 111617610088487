require("react");
import Link from 'next/link';
import cookies from 'next-cookies';
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "next-i18next";
import hooks from "./LoginWithPhoneNumberHook";
import MainButton from '../../components/ButtonsCont/MainButton';
import PhoneInputComponent from '../../components/TextInput/PhoneInput';
import NumberFormatInput from '../../components/TextInput/LoginInputs/NumberFormatInput';
import styles from "../../styles/login.module.scss";
import validation from '../../utils/validation';


function LoginWithPhoneNumber(props) {
    const {
        enterPhoneNumber,
        enterCode,
        send_code,
        send_phone_number,
        handleOpen,
        handleClose,
        handleSubmited,
        handleShowCode,
        loginWithPhoneNumber,
        keyPressPhoneNumber,
        keyPressCode,
        submited,
        loading,
        phoneNumber,
        code,
        showCode,
        showLoginWithPhoneNumber } = hooks(props);
    const { t } = useTranslation();
    return (
        <>
            {showLoginWithPhoneNumber
                ? <div className={styles.show_login_with_phoneNumber}>
                   { !showCode &&<>
                        <div className="phone-input-login">
                            <PhoneInputComponent
                                placeholder="+7 (____)"
                                value={phoneNumber.value}
                                onChange={(value) => { enterPhoneNumber(validation({ value, type: "phone_number" })); }}
                            />
                            {!phoneNumber.valid && submited ? <span className={styles.phone_valid}>
                                {phoneNumber.value && phoneNumber.value.length === 0 ? t("empty_error") :
                                    t("phone_error")}</span> : null}
                        </div>
                        <div className="d-flex justify-content-center">
                            <MainButton onClick={send_phone_number} title={loading
                                ? <ClipLoader color="#464646" size={15} />
                                : t("send")} />
                        </div>
                    </>}
                    {showCode && <div className={styles.forget_input}>
                        <NumberFormatInput
                            className='forget_input_code'
                            type="code"
                            placeholder={t('phoneCode')}
                            valid={code.valid}
                            value={code.value}
                            submitted={submited}
                            error_message={t("empty_error")}
                            onKeyDown={(e) => keyPress(e)}
                            onChange={(value) => { enterCode(validation({ value, type: "code" })); }}
                        />
                        <div className="d-flex justify-content-center">
                            <MainButton
                                onClick={send_code}
                                title={loading
                                    ? <ClipLoader color="#464646" size={15} />
                                    : t("send")}
                            />
                        </div>
                    </div>}
                </div>
                : <span className={styles.show_login_with_phoneNumber_label} onClick={loginWithPhoneNumber}>{t('loginWithPhoneNumber')}</span>}
        </>
    );
}

export default LoginWithPhoneNumber;