import { useCallback, useState } from "react";
import { useRouter } from "next/router";
import { useDispatch } from 'react-redux';
import { login_saga_code, login_saga_phone_number } from "../../store/actions/login-regis";
// import { change_password_saga } from "../../store/actions/my-info";

const LoginWithPhoneNumberHook = (props) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const [phoneNumber, setPhoneNumber] = useState({value:"", valid:false});
    const [code, setCode] = useState({value:"", valid:false});
    const [submited, setSubmited] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showCode, setShowCode] = useState(false);
    const [showLoginWithPhoneNumber, setShowLoginWithPhoneNumber] = useState(false);

    const keyPressPhoneNumber = (e) => {
        if (e.key === "Enter") {
            send_phone_number()
        }
    }
    const keyPressCode = (e) => {
        if (e.key === "Enter") {
            send_code()
        }
    }
    const send_phone_number = useCallback(() => {
        setSubmited(true);
        if (phoneNumber.valid) {
            setLoading(true);
            dispatch(login_saga_phone_number({
                phone_number:phoneNumber.value,
                cb: () => {
                    setLoading(false);
                    setSubmited(false);
                    
                },
                cbShowCode: () => {
                    setLoading(false);
                    setSubmited(false);
                    setShowCode(true);
                }
            }))
        }
    }, [phoneNumber])
    const send_code = useCallback(() => {
        setSubmited(true);
        if (code.valid) {
            setLoading(true);
            dispatch(login_saga_code({
                code:code.value.replace(/\s/g, ''),
                phone_number:phoneNumber.value,
                cb: () => setLoading(false),
                cbGoHome:()=> router.push('/')
            }))
        }
    }, [code])
    const enterPhoneNumber = useCallback((value) => setPhoneNumber(value), [])
    const enterCode = useCallback((value) => setCode(value), [])
    const handleOpen = useCallback(() => setOpen(true), [])
    const handleClose = useCallback(() => setOpen(false), [])
    const handleSubmited = useCallback(() => setSubmited(!submited), [submited])
    const handleShowCode = useCallback(() => setShowCode(!showCode), [showCode])
    const loginWithPhoneNumber = useCallback(() => setShowLoginWithPhoneNumber(!showCode), [showCode])
    return {
        enterPhoneNumber,
        enterCode, 
        send_code,
        send_phone_number,
        handleOpen,
        handleClose,
        handleSubmited,
        handleShowCode,
        loginWithPhoneNumber,
        keyPressPhoneNumber,
        keyPressCode,
        submited,
        loading,
        phoneNumber,
        code,
        showCode,
        showLoginWithPhoneNumber
    }
}
export default LoginWithPhoneNumberHook;