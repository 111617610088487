import dynamic from 'next/dynamic';
require("react");
import cookies from 'next-cookies';
import { useTranslation } from "next-i18next";
import { useSelector } from 'react-redux';
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import ClipLoader from "react-spinners/ClipLoader";
import { useRouter } from "next/router";
import SelectOptions from "../components/TextInput/SelectOption";
import styles from "../styles/login.module.scss";
import hooks from "../hooks/loginHook";
import validation from "../utils/validation";
import { wrapper } from '../store';
import { useCallback, useState } from 'react';
import authApi from '../services/authinstance';
import { get_language } from '../store/actions/general';
import keys from '../services/keys';
import LoginWithPhoneNumber from '../view/LoginWithPhoneNumber/LoginWithPhoneNumber';

const MainButton = dynamic(() => import('../components/ButtonsCont/MainButton'), {
    ssr: false
});
const LoginInput = dynamic(() => import('../components/TextInput/LoginInputs/LoginInputs'), {
    ssr: false
});
export const getServerSideProps = wrapper.getServerSideProps(async (ctx) => {
    const { token } = cookies(ctx);
    try {
        const language = await authApi.get(`${keys.SERVER_URL}utils/language/`);
        ctx.store.dispatch(get_language({ ...language.data }));
    } catch (error) {
        console.log(error);
    }

    if (!token) {
        return {
            props: {
                loggedIn: false,
                ...(await serverSideTranslations(ctx.locale, ["common"]))
            }
        };
    } else {
        return {
            props: {
                loggedIn: true,
                ...(await serverSideTranslations(ctx.locale, ["common"]))
            }

        };
    }
});
function Login(props) {
    const router = useRouter();
    const {
        login,
        username,
        password,
        translate,
        loader,
        loginWithNumber,
        handleLoginWithNumber,
        set_username,
        set_password,
        login_saga,
        handleClose,
        keyPress,
    } = hooks(props);

    const { t, i18n } = useTranslation();
    const language = useSelector(state => state.language);
    const settings = useSelector(state => state.settings);
    const [lang, setLanguage] = useState("fr");
    const handleClick = (href) => {
        router.push(href);
    };

    const settings_post = (data) => {
        dispatch(
            settings_saga({ ...data, add: !settings.data.id ? true : false })
        );
    };
    const select_language = (id) => {
        id === 1 ? setLanguage("fr") : setLanguage("us");
        i18n.changeLanguage(lang);
        settings_post({
            ...settings.data[0],
            "language": value,
            user: props.user_id
        });
    };
    return (
        <div className={styles.start}>
            <div className={`d-flex justify-content-end align-items-center start-select ${styles.start_language}`}>
                <SelectOptions
                    inputProps={{
                        name: 'language',
                    }}
                    language={props.language?.toUpperCase()}
                    value={settings.data.language}
                    list={language.results}
                    handleChange={(e) => {
                        let value = e.target.value;
                        select_language(value);
                    }} />
                {/* <img className={styles.world} src='/images/world.png' /> */}
            </div>
            <div className={styles.login}>
                <div className="container d-flex flex-column align-items-center justify-content-center">
                    <img className={styles.login_logo} src="/loginlogo.svg" />
                    <LoginInput
                        icon={<span className="icon-user" />}
                        onKeyDown={(e) => keyPress(e)}
                        value={username.value}
                        valid={username.valid}
                        submitted={login.submitted}
                        error_message="error username"
                        type="text"
                        placeholder={t("userNameLabel")}
                        onChange={(value) => {
                            set_username(validation({
                                label: username,
                                value,
                                type: "text"
                            }));

                        }}
                    />
                    <LoginInput
                        icon={<span className="icon-padlock"></span>}
                        onKeyDown={(e) => keyPress(e)}
                        value={password.value}
                        valid={password.valid}
                        submitted={login.submitted}
                        error_message="error passsword"
                        type="password"
                        placeholder={t("passwordLabel")}
                        onChange={(value) => {
                            set_password(validation({
                                label: password,
                                value,
                                type: "password"
                            }));
                        }}
                    />

                    <div className={styles.button_cont}>
                        {login.open_alert && !login.token
                            ? <span className={styles.error_message}>
                                {translate[login.data.error_message]}
                            </span>
                            : null}
                        <MainButton
                            disabled={loader}
                            title={loader
                                ? <ClipLoader color="#464646" size={15} />
                                : t("loginLabel")} onClick={() => {
                                    if (!loader) {
                                        login_saga();
                                    }

                                }} />
                    </div>
                    <div className={styles.bottom_text}>
                        {t("youForgotLoginOrPasswordLabel")}
                    </div>
                    <a className={styles.title}
                        onClick={() => handleClick("/forget-password-username")}>
                        {t("restoreLabel")}
                    </a>
                </div>
                <div className="d-flex justify-content-start">
                    <a target='blank' href='https://apps.apple.com/us/app/beautycils/id1611889051'>
                        <div style={{ backgroundImage: "url(" + "/landingbottom1.svg" + ")" }}
                            className={styles.section_button}>
                        </div>
                    </a>
                    <a target='blank' href='https://play.google.com/store/apps/details?id=com.beautycils.app'>
                        <div style={{ backgroundImage: "url(" + "/landingbottom2.svg" + ")" }}
                            className={styles.section_button}>
                        </div>
                    </a>
                </div>
                <LoginWithPhoneNumber />
            </div>
        </div>
    );
}
// Login.getInitialProps = async (ctx) => {
//     const { token } = cookies(ctx);
//     if (!token) {
//         return { loggedIn: false }
//     } else {
//         return {
//             loggedIn: true
//         }
//     }
// };
export default Login;