import styles from "./Button.module.scss";
function MainButton({ title, onClick, active, disabled, ref, preventDefaultOff }) {
    return (
        <button
            ref={ref}
            disabled={disabled}
            onClick={e => {
                if (!preventDefaultOff) {
                    e.preventDefault();
                }
                if (!disabled) {
                    onClick();
                } else {
                    null;
                }
            }}
            className={!disabled
                ? (active
                    ? styles.active_btn
                    : styles.main_btn)
                : styles.main_disabed_btn}>{title}</button>
    );
}
export default MainButton;