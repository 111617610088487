import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useState } from 'react';
// import { isValidPhoneNumber } from 'react-phone-number-input'

function PhoneInputComponent(props) {
    let currentCountryCode = 'FR';
    return (
        <PhoneInput
            autoComplete=''
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            defaultCountry={'FR'}
            countries={["AM", "ZA", "DZ", "DE",
                "AND", "AR", "AUS",
                "AT", "BE", "BJ",
                "BR", "BG", "BF",
                "CM", "CA", "CIV",
                "DK", "UAE", "ES",
                "USA", "FR", "GA",
                "GB", "GR", "FR_GUADELOUPE",
                "GN", "FR_BUYANE", "IRL",
                "ISR", "FR_REUNION", "LB",
                "LU", "MG", "MA",
                "FR_MARTINIQUE", "MU", "MCO",
                "NC", "PS", "NL",
                "FR_POLYNESIE", "PT", "QA",
                "CD", "RU", "SN",
                "SD", "SWE", "CH", "TN"
            ]}
            international
            placeholder={props.placeholder}
            value={props.value}
            defaultValue="+33"
            onChange={(value, country) => {
                props.onChange(value, currentCountryCode);
            }}
            onCountryChange={code => currentCountryCode = code}
        />
    );
}
export default PhoneInputComponent;